import { Provider, inject } from '@angular/core';
import { AnalyticsService } from '@konnektu/analytics-data';
import { AuthService } from '@konnektu/auth';
import { isDefined } from '@konnektu/helpers';
import {
  MENU_ITEMS,
  MENU_MAIN_PATH,
  SidenavItem
} from '@konnektu/sidebar-layout';
import { concatMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

const createFullMenu = (
  tenantCode: string,
  dashboards: { Id: number; Name: string }[]
): SidenavItem[] => [
  {
    id: 'dashboard',
    label: 'menu.entry.dashboard.title',
    icon: 'knkTech' as const,
    children: dashboards.map((dashboard) => ({
      id: `dashboard-${dashboard.Id}`,
      label: dashboard.Name,
      route: `/${tenantCode}/analytics/dashboard/${dashboard.Id}`,
      icon: 'knkList'
    }))
  },
  {
    id: 'segments',
    label: 'menu.entry.segments.title',
    icon: 'knkSegments' as const,
    route: `/${tenantCode}/segments`
  },
  {
    id: 'clients',
    label: 'menu.entry.clients',
    icon: 'knkCustomerCard',
    route: `${tenantCode}/clients`
  },
  {
    id: 'pixels',
    label: 'menu.entry.pixels.title',
    route: `/${tenantCode}/pixels`,
    icon: 'knkList' as const
  },
  {
    id: 'targetAudiences',
    label: 'menu.entry.targetAudiences',
    icon: 'knkCustomerCard',
    route: `/${tenantCode}/audiences`
  },
  {
    id: 'schemas',
    label: 'menu.entry.campaignSchemas',
    route: `/${tenantCode}/campaign-schemas`,
    icon: 'knkFlow' as const
  },
  {
    id: 'communications',
    label: 'menu.entry.communication.title',
    icon: 'knkCommunication' as const,
    children: [
      {
        id: 'templates',
        icon: 'knkCommunication',
        label: 'menu.entry.templates.menuLabel',
        route: `/${tenantCode}/communications`
      },
      {
        id: 'html-fragments',
        icon: 'knkCommunication',
        label: 'menu.entry.fragments.menuLabel',
        route: `/${tenantCode}/communications/html-fragments`
      }
    ]
  },
  {
    id: 'calendar',
    label: 'menu.entry.calendar',
    route: `/${tenantCode}/calendar`,
    icon: 'knkClock' as const
  },
  {
    id: 'advertisingCampaigns',
    label: 'menu.entry.advertisingCampaigns.title',
    icon: 'knkList' as const,
    children: [
      {
        id: 'cabinets',
        label: 'menu.entry.advertisingCampaigns.cabinets',
        route: `/${tenantCode}/segments/exports/cabinets`,
        icon: 'knkList' as const
      },
      {
        id: 'jobs',
        label: 'menu.entry.advertisingCampaigns.media',
        route: `/${tenantCode}/segments/exports`,
        icon: 'knkList' as const
      }
    ]
  },
  {
    id: 'yandex-gpt',
    label: 'menu.entry.gpt.title',
    icon: 'knkAi' as const,
    route: `/${tenantCode}/yandex-gpt`
  }
];

const createPhotoShowcaseMenu = (tenantCode: string): SidenavItem[] => [
  {
    id: 'photo-showcase',
    label: 'menu.entry.photoShowcase.title',
    icon: 'knkImage' as const,
    route: `/${tenantCode}/photo-showcase`
  }
];

export const providePharmzillaMenuItems = (tenantCode: string): Provider => ({
  provide: MENU_ITEMS,
  useFactory: () => {
    const authService = inject(AuthService);

    const analyticsService = inject(AnalyticsService);

    return authService.currentOperatorId$.pipe(
      filter(isDefined),
      concatMap((operatorId) =>
        analyticsService.queryAllDashboards().pipe(
          map((dashboards) => {
            const photoShowcaseInstanceConfigForTenant =
              environment.photoShowcaseInstanceConfig[tenantCode];
            const isPhotoShowcaseOnly =
              photoShowcaseInstanceConfigForTenant &&
              photoShowcaseInstanceConfigForTenant.includes(operatorId);
            return isPhotoShowcaseOnly
              ? createPhotoShowcaseMenu(tenantCode)
              : createFullMenu(tenantCode, dashboards);
          })
        )
      )
    );
  }
});

export const providePharmzillaMainPath = (tenantCode: string): Provider => ({
  provide: MENU_MAIN_PATH,
  useValue: `${tenantCode}/clients`
});
