import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Routes } from '@angular/router';
import { AcsGuard } from '@konnektu/acs-helpers';
import {
  CommunicationApiService,
  CommunicationMessageDto
} from '@konnektu/communication/api';
import { LayoutComponent } from '@konnektu/sidebar-layout';
import { format } from 'date-fns';
import { catchError, throwError } from 'rxjs';
import { PharmzillaCommunicationService } from './communication/pharmzilla-communication.service';

export function createPhotoShowcaseRoutes(
  tenantCode: string,
  authGuard: CanActivateFn
): Routes {
  return [
    {
      path: tenantCode,
      children: [
        {
          path: 'login',
          loadComponent: () =>
            import('@konnektu/login-page').then((l) => l.LoginPageComponent)
        },
        {
          path: '',
          canActivate: [authGuard, AcsGuard],
          canActivateChild: [authGuard, AcsGuard],
          children: [
            {
              path: '',
              redirectTo: 'photo-showcase',
              pathMatch: 'full'
            },
            {
              path: '',
              component: LayoutComponent,
              data: {
                breadcrumb: 'breadcrumbs.main'
              },
              children: [
                {
                  path: 'photo-showcase',
                  data: {
                    breadcrumb: 'menu.entry.photoShowcase.title'
                  },
                  loadComponent: () =>
                    import('@konnektu/photo-showcase').then(
                      (c) => c.PhotoShowcaseComponent
                    )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '**',
      redirectTo: `${tenantCode}/photo-showcase`
    }
  ];
}

export function createTenantRoutes(
  tenantCode: string,
  authGuard: CanActivateFn
): Routes {
  return [
    {
      path: tenantCode,
      children: [
        {
          path: 'cabinet-response',
          loadComponent: () =>
            import('@konnektu/segments/export').then(
              (m) => m.OauthRedirectPageComponent
            )
        },
        {
          path: 'login',
          loadComponent: () =>
            import('@konnektu/login-page').then((l) => l.LoginPageComponent)
        },
        {
          path: '',
          canActivate: [authGuard, AcsGuard],
          canActivateChild: [authGuard, AcsGuard],
          children: [
            {
              path: '',
              redirectTo: 'clients',
              pathMatch: 'full'
            },
            {
              path: '',
              component: LayoutComponent,
              data: {
                breadcrumb: 'breadcrumbs.main'
              },
              children: [
                {
                  path: 'segments/exports',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.advertisingCampaigns.title',
                      link: null
                    }
                  },
                  children: [
                    {
                      path: '',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.ExportJobSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.media'
                      }
                    },
                    {
                      path: 'cabinets',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.CabinetSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.cabinets'
                      }
                    }
                  ]
                },
                {
                  path: 'yandex-gpt',
                  data: {
                    breadcrumb: 'menu.entry.gpt.title'
                  },
                  loadComponent: () =>
                    import('@konnektu/yandex-gpt/view').then(
                      (c) => c.YandexGptChatComponent
                    )
                },
                {
                  path: 'pixels',
                  loadChildren: () =>
                    import('@konnektu/pixel/view').then((m) => m.PIXEL_ROUTES)
                },
                {
                  path: 'segments',
                  loadChildren: () =>
                    import('@konnektu/segments-view').then(
                      (m) => m.SegmentsModule
                    )
                },
                {
                  path: 'calendar',
                  loadChildren: () =>
                    import('@konnektu/calendar').then((m) => m.CalendarModule)
                },
                {
                  path: 'communications',
                  children: [
                    {
                      path: '',
                      data: {
                        breadcrumb: 'menu.entry.communication.title'
                      },
                      children: [
                        {
                          path: 'html-fragments',
                          data: {
                            breadcrumb: 'menu.entry.fragments.menuLabel'
                          },
                          loadChildren: () =>
                            import(
                              '@konnektu/communication/html-fragments'
                            ).then((m) => m.HTML_FRAGMENT_ROUTES)
                        },
                        {
                          path: '',
                          loadComponent: () =>
                            import('@konnektu/communication/section').then(
                              (c) => c.CommunicationSectionComponent
                            ),
                          data: { breadcrumb: null }
                        },
                        {
                          path: ':communicationId',
                          data: {
                            breadcrumb: (data: {
                              communication: { name: string };
                            }) => data.communication.name
                          },
                          resolve: {
                            communication: (route: ActivatedRouteSnapshot) => {
                              const communicationService = inject(
                                CommunicationApiService
                              );
                              return communicationService.getTemplate(
                                parseInt(route.params['communicationId'], 10)
                              );
                            }
                          },
                          children: [
                            {
                              path: '',
                              data: { breadcrumb: null },
                              loadComponent: () =>
                                import(
                                  '../app/communication/pharmzilla-communication.component'
                                ).then(
                                  (c) => c.PharmzillaCommunicationComponent
                                )
                            },
                            {
                              path: 'messages',
                              data: {
                                breadcrumb: 'communicationMessages.menuLabel'
                              },
                              children: [
                                {
                                  path: ':messageId',
                                  resolve: {
                                    message: (
                                      route: ActivatedRouteSnapshot
                                    ) => {
                                      const communicationService = inject(
                                        CommunicationApiService
                                      );
                                      const id = parseInt(
                                        route.paramMap.get(
                                          'messageId'
                                        ) as string,
                                        10
                                      );
                                      return communicationService.getMessage(
                                        id
                                      );
                                    }
                                  },
                                  data: {
                                    breadcrumb: (data: {
                                      message: CommunicationMessageDto;
                                    }) =>
                                      format(
                                        new Date(data.message.startedAt),
                                        'dd.MM.yyyy HH:mm'
                                      )
                                  },
                                  loadComponent: () =>
                                    import(
                                      '@konnektu/communication/message-card'
                                    ).then((c) => c.MessageCardComponent)
                                }
                              ]
                            },
                            {
                              path: 'settings',
                              resolve: {
                                communicationSettings: (
                                  route: ActivatedRouteSnapshot
                                ) => {
                                  const pharmzillaCommunicationSettingService =
                                    inject(PharmzillaCommunicationService);
                                  const communicationId = parseInt(
                                    route.params['communicationId'],
                                    10
                                  );
                                  return pharmzillaCommunicationSettingService
                                    .getSettings(communicationId)
                                    .pipe(
                                      catchError((error) => {
                                        if (
                                          error instanceof HttpErrorResponse &&
                                          error.status === 404
                                        ) {
                                          return pharmzillaCommunicationSettingService.saveDefaultSettings(
                                            communicationId
                                          );
                                        }
                                        return throwError(() => error);
                                      })
                                    );
                                }
                              },
                              data: {
                                breadcrumb: (data: {
                                  communication: { name: string; id: number };
                                }) => ({
                                  label: data.communication.name,
                                  link: `communications/${data.communication.id}`
                                })
                              },
                              loadComponent: () =>
                                import(
                                  './communication/upharma-settings/upharma-settings.component'
                                ).then((c) => c.UpharmaSettingsComponent)
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'campaign-schemas',
                  loadChildren: () =>
                    import('@konnektu/campaign-schema').then(
                      (m) => m.CampaignSchemaModule
                    )
                },
                {
                  path: 'clients',
                  loadChildren: () =>
                    import('./clients/client.routes').then(
                      (r) => r.CLIENT_ROUTES
                    )
                },
                {
                  path: 'audiences',
                  loadChildren: () =>
                    import('./target-audiences/target-audience.routes').then(
                      (r) => r.TARGET_AUDIENCE_ROUTES
                    )
                },
                {
                  path: 'analytics',
                  loadChildren: () =>
                    import('@konnektu/analytics-view').then(
                      (a) => a.AnalyticsViewRoutingModule
                    ),
                  data: {
                    preload: true
                  }
                },
                {
                  path: 'photo-showcase',
                  data: {
                    breadcrumb: 'menu.entry.photoShowcase.title'
                  },
                  loadComponent: () =>
                    import('@konnektu/photo-showcase').then(
                      (c) => c.PhotoShowcaseComponent
                    )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '**',
      loadComponent: () =>
        import('@konnektu/status-page').then((c) => c.NotFoundPageComponent)
    }
  ];
}
