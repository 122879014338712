import { HttpClient, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { bootstrapTenantApplication } from '@konnektu/multitenant';

import { createContextLogger } from '@konnektu/helpers';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NotFoundPageComponent } from '@konnektu/status-page';
import { provideKonnektuTaiga } from '@konnektu/taiga';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';
import { AppComponent } from './app/app.component';
import { bootstrapTenant } from './bootstrap-tenant';

export function initStaticTranslations(translateService: TranslateService) {
  return () =>
    new Promise<void>((resolve) => {
      const logger = createContextLogger('StaticTranslationInitializer');
      translateService.langs = ['ru', 'en'];
      translateService.onLangChange.subscribe((event) => {
        localStorage.setItem('lang', event.lang);
      });
      const langToSet = localStorage.getItem('lang') ?? 'ru';
      if (langToSet !== translateService.currentLang) {
        translateService.use(langToSet);
      }
      logger.debug('Initialized static translations');
      resolve();
    });
}

export function provideStaticTranslations() {
  return [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
          deps: [HttpClient]
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler
        },
        defaultLanguage: 'ru'
      })
    ),
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['en', 'ru'] }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initStaticTranslations,
      deps: [TranslateService]
    }
  ];
}

export async function bootstrapNotFound() {
  await bootstrapApplication(AppComponent, {
    providers: [
      provideAnimations(),
      provideRouter([
        {
          path: '**',
          component: NotFoundPageComponent
        }
      ]),
      provideHttpClient(),
      provideStaticTranslations(),
      provideKonnektuTaiga()
    ]
  });
}

bootstrapTenantApplication({
  tenantCodeProvider: () => window.location.pathname.split('/')[1],
  checkTenant: (code) =>
    fetch(`${code}/ui-api/auth/checkinstance`)
      .then((resp) => resp.json())
      .then((result) => result?.data),
  tenantFound: bootstrapTenant,
  tenantNotFound: bootstrapNotFound
});
