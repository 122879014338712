import { CommunicationChannelIds } from '@konnektu/domain-models';
import { and, equal, exist } from '@konnektu/metastore';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ConditionBlockDef,
  SimpleFilterTemplate
} from '@konnektu/segments-view';

const DEFAULT_COLUMNS = [
  { column: 'Id', index: true },
  { column: 'Value', index: true },
  { column: 'User.Id', index: false },
  { column: 'User.Surname', index: false },
  { column: 'User.GivenName', index: false },
  { column: 'User.MiddleName', index: false }
];

export const DEFAULT_CONDITION_BLOCKS: ConditionBlockDef[] = [
  {
    id: 'targetAudienceBlock',
    label: 'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.label',
    conditions: [
      {
        id: 'clientSpecialization',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientSpecialization',
        type: 'lookup-remote',
        entityName: 'Specialization',
        createExpression: (state, defaultCreateExpression) =>
          exist(
            'User.SpecializationToUsers',
            and(
              equal('IsActive', true),
              defaultCreateExpression('SpecializationId', state)
            )
          )
      },
      {
        id: 'clientTarget',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientTarget',
        type: 'lookup-remote',
        entityName: 'Target',
        createExpression: (state, defaultCreateExpression) =>
          exist(
            'User.SpecializationToUsers',
            and(
              equal('IsActive', true),
              defaultCreateExpression('Specialization.TargetId', state)
            )
          )
      },
      {
        id: 'clientCountry',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientCountry',
        type: 'lookup-remote',
        entityName: 'Country',
        createExpression: (state, defaultCreateExpression) =>
          exist(
            'User.UserWorkPlaces.Organization.OrganizationToAddresses',
            and(
              equal('IsActive', true),
              defaultCreateExpression('Address.CountryId', state)
            )
          )
      },
      {
        id: 'clientRegion',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientRegion',
        type: 'lookup-remote',
        entityName: 'Region',
        createExpression: (state, defaultCreateExpression) =>
          exist(
            'User.UserWorkPlaces.Organization.OrganizationToAddresses',
            and(
              equal('IsActive', true),
              defaultCreateExpression('Address.RegionId', state)
            )
          )
      },
      {
        id: 'clientGender',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientGender',
        type: 'lookup',
        entityName: 'Gender',
        column: 'User.GenderId'
      },
      {
        id: 'clientIsTest',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientIsTest',
        type: 'checkbox',
        state: true,
        createExpression: (state, defaultCreateExpression) =>
          defaultCreateExpression('User.IsTest', !state)
      },
      {
        id: 'clientIsArchive',
        label:
          'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientIsArchive',
        type: 'checkbox',
        state: true,
        createExpression: (state, defaultCreateExpression) =>
          defaultCreateExpression('User.UserInfo.IsArchive', !state)
      }
    ]
  },
  {
    label: 'segmentEditor.simpleFilter.baseTemplate.consentBlock.label',
    id: 'consentBlock',
    conditions: [
      {
        id: 'personalDataAgree',
        label:
          'segmentEditor.simpleFilter.baseTemplate.consentBlock.conditions.personalDataAgree',
        type: 'checkbox',
        column: 'User.PersonalDataAgree',
        state: true
      },
      {
        id: 'writtingAgree',
        label:
          'segmentEditor.simpleFilter.baseTemplate.consentBlock.conditions.writtingAgree',
        type: 'checkbox',
        column: 'User.UserInfo.WrittingAgree',
        state: true
      },
      {
        id: 'electronicAgree',
        label:
          'segmentEditor.simpleFilter.baseTemplate.consentBlock.conditions.electronicAgree',
        type: 'checkbox',
        column: 'User.UserInfo.ElectronicAgree',
        state: true
      }
    ]
  }
];

export const PHARMA_SIMPLE_FILTER_TEMPLATES: SimpleFilterTemplate[] = [
  {
    id: 'pharmzillaEmailCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.emailCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: and(
      equal('Type.CommunicationChannelId', CommunicationChannelIds.Email),
      equal('IsActive', true)
    ),
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'pharmzillaSmsCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.smsCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: and(
      equal('Type.CommunicationChannelId', CommunicationChannelIds.Sms),
      equal('IsActive', true)
    ),
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'pharmzillaTelegramCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.telegramCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: and(
      equal('Type.CommunicationChannelId', CommunicationChannelIds.Telegram),
      equal('IsActive', true)
    ),
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'pharmzillaWhatsAppCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.whatsAppCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: and(
      equal('Type.CommunicationChannelId', CommunicationChannelIds.WhatsApp),
      equal('IsActive', true)
    ),
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'pharmzillaViberCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.viberCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: and(
      equal('Type.CommunicationChannelId', CommunicationChannelIds.Viber),
      equal('IsActive', true)
    ),
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  }
];
