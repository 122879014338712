import { Injectable, Type, inject } from '@angular/core';
import { exist } from '@konnektu/metastore';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConditionBlockDef, ConditionDef } from '@konnektu/segments-view';
import { TenantFeaturesService } from '../clients/tenant-config';
import {
  DEFAULT_CONDITION_BLOCKS,
  PHARMA_SIMPLE_FILTER_TEMPLATES
} from './simple-filter-templates';

@Injectable({ providedIn: 'root' })
export class FilterConfigService {
  private readonly tenantFeatures = inject(TenantFeaturesService);

  getFilterTemplates() {
    return PHARMA_SIMPLE_FILTER_TEMPLATES.map((template) => ({
      ...template,
      conditionBlocks: this.getConditionBlocks()
    }));
  }

  private getConditionBlocks(): ConditionBlockDef[] {
    const isTextMode = this.tenantFeatures.features().showRegionCity;

    if (!isTextMode) {
      return DEFAULT_CONDITION_BLOCKS;
    }

    return DEFAULT_CONDITION_BLOCKS.map((block) => {
      if (block.id === 'targetAudienceBlock') {
        const filteredConditions = block.conditions.filter(
          (condition) => condition.id !== 'clientCountry'
        );

        const cityCondition: ConditionDef = {
          id: 'clientCity',
          label:
            'segmentEditor.simpleFilter.baseTemplate.targetAudienceBlock.conditions.clientCity',
          type: 'string' as unknown as Type<'string'>,
          createExpression: (
            state: object,
            defaultCreateExpression: (column: string, state: object) => object
          ): object =>
            exist('User.UserInfos', defaultCreateExpression('City', state))
        };

        const regionIndex = filteredConditions.findIndex(
          (condition) => condition.id === 'clientRegion'
        );

        const updatedConditions = filteredConditions.map((condition) => {
          if (condition.id === 'clientRegion') {
            return {
              id: 'clientRegion',
              label: condition.label,
              type: 'string' as unknown as Type<'string'>,
              createExpression: (
                state: object,
                defaultCreateExpression: (
                  column: string,
                  state: object
                ) => object
              ): object =>
                exist(
                  'User.UserInfos',
                  defaultCreateExpression('Region', state)
                )
            };
          }
          return condition;
        });

        updatedConditions.splice(regionIndex + 1, 0, cityCondition);

        return {
          ...block,
          conditions: updatedConditions as ConditionDef[]
        };
      }
      return block;
    });
  }
}
